








































import Vue from 'vue';
import { QVueGlobals } from 'quasar';

export default Vue.extend({
  name: 'Academy-Page',
  data () {
    return {
      url: 'https://www.youtube-nocookie.com/embed/2HROxvKFpos',
      startAt: null as unknown as number,
      autoPlay: false,
      timeline: [
        {
          timestamp: '00:07',
          title: 'Introduction to the Dashboard',
          side: 'left',
        },
        {
          timestamp: '00:21',
          title: 'Managing your portfolio',
          side: 'right',
        },
        {
          timestamp: '01:16',
          title: 'Sharing your Portfolio',
          side: 'left',
        },
        {
          timestamp: '01:38',
          title: 'Handling Enquiries',
          side: 'right',
        },
        {
          timestamp: '02:18',
          title: 'Managing a Tenancy',
          side: 'left',
        },
        {
          timestamp: '05:05',
          title: 'Ideas',
          side: 'right',
        },
      ] as QTimelineEntry[],
    };
  },
  computed: {
    academyURL (): string {
      let url: string = this.url;
      if (this.startAt) {
        url = url + '?start=' + this.startAt;
      }
      return url;
    },
    layout (): string {
      return (this.$q as QVueGlobals).screen.lt.sm ? 'dense' : ((this.$q as QVueGlobals).screen.lt.md ? 'comfortable' : 'loose');
    },
  },
  // Methods
  methods: {
    hmsToSeconds (timestring: string) {
      let seconds: number = 0;
      const splitTime: string[] = timestring.split(':');
      if (splitTime && splitTime.length === 2) {
        seconds = (parseInt(splitTime.pop() as string)) + (parseInt(splitTime.pop() as string) * 60);
      } else {
        console.error('Timestring Unsupported. Try MM:SS');
      }
      this.startAt = seconds;
      // Scroll to top
      this.$nextTick().then(() => {
        console.log(this.academyURL);
        document.getElementById('academyPage')!.scrollTop = 0;
      });
    },
  },
});

interface QTimelineEntry {
  timestamp: string;
  title: string;
  side: 'left' | 'right';
}
